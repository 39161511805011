import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';


@Injectable({
    providedIn: 'root'
  })
export class ConfirmDeactivateGuard implements CanDeactivate<any> {
    
    canDeactivate(target: any) { 

        if (target.hasChanges) {
        
            return window.confirm('Do you really want to cancel?');

        }

        return true;

    }

}